<template>
  <div class="words-card-container">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="words-card-page-box">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div class="word-cards-content" :class="'lesson-' + lessonNum">
        <div class="title-area">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
        </div>
        <div
          class="word-card-item"
          :class="'lesson-' + lessonNum"
          v-for="(item, index) in wordCardList[currentIndex]"
          :key="index + 'cards'"
        >
          <div
            class="contray-content"
            :class="{
              blueColor: item.Showing,
              redColor : !item.Showing,
              'card-1': index === 0,
              'card-2': index === 1,
              'card-3': index === 2,
              'card-4': index === 3,
              'card-5': index === 4,
            }"
            @click="clickImage(index)"
          >
            <img :src="item.Showing ? item.imgShow : item.image" alt="" />
          </div>

          <div class="words-box" v-if="lessonNum!==53">
            <div
              class="hanzi-item"
              v-for="(item, index) in wordCardList[currentIndex]"
              :key="index"
              v-show="showHnaziIndex === index"
            >
              <div class="pinyin sentence-hanzi font-pinyin-medium">
                {{ item.pinyin }}
              </div>
              <div class="sentence-hanzi font-hanzi-medium">
                {{ item.hanzi }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  name: "WordImgGroupCards",
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  props: {
    wordCardList: {
      type: Array,
      require: true,
      default: () => [],
    },
    lessonNum: {
      type: Number,
      require: false,
    },
    titleInfo: {
      type: Object,
      default: () => {},
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: 0,
      numBers: 1,
      showHnaziIndex: false,
      StarArr: [],
      isLastStep: false,
      selecteArr: [],
      clickImgSocketInfo: {},
      clickMenuSocketInfo:{},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  watch: {
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20007,
          data: { value },
          text: "wordCardsPage点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo:{
      handler(value) {
        const data = {
          clickType: 2000701,
          data: { value },
          text: "wordCardsPage点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    }
  },
  mounted() {
    this.$bus.$on("clickSingleCard", ({ index }) => {
      this.clickImage(index, true);
    });
    this.$bus.$on("clickGroupCardsMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickSingleCard");
    this.$bus.$off("clickGroupCardsMenu");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.currentIndex = index - 1;
      this.selecteArr = []
    },
    clickImage(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.$emit("changeTheImg", index, this.currentIndex);

      playCorrectSound(true, false);
      if (this.selecteArr.indexOf(index) === -1) {
        this.selecteArr.push(index);
      }

      this.showHnaziIndex = index;
      if (this.selecteArr.length === this.wordCardList[this.wordCardList.length-1].length && this.currentIndex === this.wordCardList.length - 1) {
        this.isLastStep = true;
        playCorrectSound();
        startConfetti();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.words-card-container {
  width: 100%;
  height: 100%;
}

.words-card-page-box {
  background: url("../../../../assets/img/03-Backgrounds/background-practice.svg")
    no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .hanzi-item {
    width: 50%;
    margin: 0 auto;
    background: #214e96;
    position: absolute;
    bottom: 6%;
    left: 25%;
    padding: 1% 1%;
    color: #fff;
    border-radius: 40px;
    .sentence-hanzi,
    .sentence-pinyin {
      text-align: center;
    }
  }

  .title-area {
    z-index: 9;
    position: absolute;
    left: 0;
    top: 10%;
    width: auto;
    height: auto;
    padding:20px;
    background: #214e96;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 22px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
  .word-cards-content {
    // margin-top: -15%;
    // margin-right: -10%;
    width: 60%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;

    .word-card-item {
      width: 29%;
      display: flex;
      flex-direction: column;
      margin: 0 2%;

      &.lesson-53 {
        width: 33%;

        img {
          width: 80% !important;
          margin: auto !important;
        }
      }

      .frontage-area {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 70%;
      }
      p {
        line-height: 40px;
      }
      .redColor {
        background: #ce4c3f;
        border-bottom-color: #a53d32;
      }
      .blueColor {
        background: #224e96;
        border-bottom-color: #173b74;
      }
      .contray-content {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 10%;
        // background: #ce4c3f;
        position: relative;
        border-radius: 18px;
        // border-bottom: 15px solid #a53d32;
        border-bottom-width: 15px;
        border-bottom-style: solid;
        vertical-align: middle;
        cursor: pointer;
        &:hover {
          border-bottom: 15px solid #f6c752;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          margin-left: 20%;
          width: 60%;
        }
      }
      .card-1 {
        transform: rotate(6deg);
      }
      .card-2 {
        transform: rotate(-2deg);
      }
      .card-3 {
        transform: rotate(0deg);
      }
      .card-4 {
        transform: rotate(7deg);
      }
      .card-5 {
        transform: rotate(6deg);
      }
    }
    .lesson-11 {
      width: 30%;
    }
     .lesson-53 {
      width: 30%;
    }
  }
  .lesson-53 {
   margin-top:20%
  }
}
</style>
